import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { Session, User } from '@supabase/auth-helpers-nextjs';

import {
  AuthContextProps,
  AuthProviderProps,
} from './authentication.definition';

export const EVENTS = {
  SIGNED_IN: 'SIGNED_IN',
  PASSWORD_RECOVERY: 'PASSWORD_RECOVERY',
  SIGNED_OUT: 'SIGNED_OUT',
  USER_UPDATED: 'USER_UPDATED',
};

export const VIEWS = {
  SIGN_IN: 'sign_in',
  SIGN_UP: 'sign_up',
  FORGOTTEN_PASSWORD: 'forgotten_password',
  MAGIC_LINK: 'magic_link',
  UPDATE_PASSWORD: 'update_password',
};

export const AuthContext = createContext<AuthContextProps>({
  initial: false,
  session: null,
  user: null,
  view: VIEWS.SIGN_IN,
  setView: () => {},
  logout: async () => ({
    error: null,
  }),
});

export function useAuth(): AuthContextProps {
  const context = useContext<AuthContextProps>(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export function AuthProvider({
  supabase,
  children,
}: AuthProviderProps): React.ReactElement {
  const router = useRouter();

  const [initial, setInitial] = useState(true);
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [view, setView] = useState(VIEWS.SIGN_IN);

  const [history, setHistory] = useState<string[]>([]);

  useEffect(() => {
    async function getActiveSession() {
      const {
        data: { session: activeSession },
      } = await supabase.auth.getSession();

      setSession(activeSession);
      setUser(activeSession?.user ?? null);
      setInitial(false);
    }
    getActiveSession();

    const {
      data: { subscription: authListener },
    } = supabase.auth.onAuthStateChange((event, currentSession) => {
      setSession(currentSession);
      setUser(currentSession?.user ?? null);

      // NOTE: Suuuuper hacky, but it always goes to sign in otherwise
      //       Due to Supabase logging you in to reset your password, but using the same event, genius...
      const _history = [...history];
      _history.push(event);

      // console.log(_history);

      setHistory(_history);
      if (
        _history[_history.length - 1] === EVENTS.SIGNED_IN &&
        _history[_history.length - 2] === EVENTS.PASSWORD_RECOVERY
      ) {
        setView(VIEWS.UPDATE_PASSWORD);
      }

      switch (event) {
        case EVENTS.PASSWORD_RECOVERY:
          // router.push('/reset-password');
          setView(VIEWS.UPDATE_PASSWORD);
          break;
        case EVENTS.SIGNED_OUT:
        case EVENTS.USER_UPDATED:
          setView(VIEWS.SIGN_IN);
          break;
        default:
      }
    });

    return () => {
      authListener?.unsubscribe();
    };
  }, []);

  const value = useMemo(() => {
    return {
      initial,
      session,
      user,
      view,
      setView,
      logout: async () => await supabase.auth.signOut(),
    };
  }, [initial, session, user, view]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
