import React, { useState } from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { createBrowserSupabaseClient } from '@supabase/auth-helpers-nextjs';

import { AuthProvider } from 'providers/authentication/authentication.provider';

// TODO: Global css module
import { GlobalStyle } from 'src/styles/global.style';

function CustomApp({ Component, pageProps }: AppProps): JSX.Element {
  const [supabase] = useState(() => createBrowserSupabaseClient());

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>
      <AuthProvider supabase={supabase}>
        <GlobalStyle />
        <Component {...pageProps} />
      </AuthProvider>
    </>
  );
}

export default CustomApp;
