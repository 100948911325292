import styled, { createGlobalStyle } from 'styled-components';
import config from './style.config.json';

// import InterRegular from '../../public/static/fonts/Inter-Light-slnt=0.ttf';
// import InterBold from '../../public/static/fonts/Inter-Medium-slnt=0.ttf';

export const GlobalStyle = createGlobalStyle({
  // '@font-face': {
  //   fontFamily: 'InterRegular',
  //   src: `url(${InterRegular})`,
  // },

  // // @ts-ignore
  // '@font-face': {
  //   fontFamily: 'InterBold',
  //   src: `url(${InterBold})`,
  // },

  '*': {
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
  },

  '*, *::before, *::after': {
    boxSizing: 'border-box',
  },

  body: {
    margin: 0,
    height: '100vh',
    color: config.font.color.primary,
    fontFamily: config.font.family.regular,
    fontWeight: config.font.weight.regular,
    fontSize: '1rem',
    backgroundColor: config.background.primary,
    overflow: 'hidden',
  },

  '#__next': {
    '&, & > div': {
      height: '100%',
    },
  },

  h1: {
    marginBottom: 0,
  },

  li: {
    marginBottom: '0.5rem',
  },
});

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 15px;
  max-width: 75rem;
  margin: 0 auto;
  z-index: 1;

  @media (min-width: ${config.breakpoints.desktop}) {
    padding: 0;
  }
`;

export const PageInner = styled.div<{ hasPagination?: boolean }>`
  position: relative;
  height: calc(100% - 75px);
  overflow-y: auto;

  @media (min-width: ${config.breakpoints.tablet}) {
    height: ${({ hasPagination }) =>
      hasPagination ? `calc(100% -  150px)` : `calc(100% -  60px)`};
    padding-right: 15px;
  }
`;
